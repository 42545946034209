import { request as axios } from '@/util/request';
import moment, { Moment } from 'moment';
const API = {
  edit_or_get: '/boss/operation/customer',
}



// 客服配置数据接口
export interface ServiceConfigData {
  mobile: string;
  qrcode_url: string;
  service_end_time: any;
  service_start_time: any;
  wechat_id: string;
}
// 初始化客服配置数据
export function initServiceConfig(remote?: ServiceConfigData): ServiceConfigData {
  remote = remote || {} as ServiceConfigData;
  return {
    mobile: remote.mobile || '',
    qrcode_url: remote.qrcode_url || '',
    service_end_time: remote.service_end_time || null,
    service_start_time: remote.service_start_time || null,
    wechat_id: remote.wechat_id || '',
  }
}
// 获取客服配置
export async function getServiceConfig() {
  return await axios.get(API.edit_or_get);
}


// 请求参数数据接口
interface SendData {
  qrcode_url: string;
  wechat_id: string;
  service_start_time: number | string;
  service_end_time: number | string;
  mobile: string;
}
// 编辑客服配置
export async function editServiceConfig(send_data: SendData) {
  return await axios.post(API.edit_or_get, {
    ...send_data,
  })
}